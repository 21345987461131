<template>
  <div class="contentValidation">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Régie</span>
          </div>

          <SelectComponent
            :options="computedgetAllRegie"
            :value="filterRegie"
            label="name"
            champName="régie"
            filterName="filterRegie"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue "
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterStatu"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <!-- <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px ">
            <span class="title-tabel">Project</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-63-px"
            v-model="filterProjectType"
            :options="[{ value: null, text: 'tous' }, ...project_type]"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div> -->
        <div class="box-label-champ mr-2" v-if="filterProjectType == 'ISO'">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Précarite</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filterPrecarite"
            :options="ListPrecarite"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="filterProjectType == 'ISO'">
          <div class="label-box-style w-103-px p-4-5">
            <span class="title-tabel">Type de chauffage</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filterChauffage"
            :options="ListChauffage"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="filterProjectType == 'ISO'">
          <div class="label-box-style w-103-px p-4-5">
            <span class="title-tabel">Zone climatique</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filterZone"
            :options="ListZone"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div v-if="getChargementFactureRegie" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <!-- <div class="box-end-position"><palatte-color /></div> -->
        <div class="box-end-position">
          <b-button
            class="button-succes-style ml-2 p-1"
            size="sm"
            :variant="this.details === false ? 'success' : 'danger'"
            @click="handleDetails"
          >
            <font-awesome-icon icon="eye" />
            <!-- <span v-if="this.details === true">Masquer les détails </span>
            <span v-else>afficher les détails </span> -->
          </b-button>
        </div>
      </div>
    </div>

    <div class="body-box-rapport">
      <palatte-color />
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableGestionRegieAdmin table-header"
          :items="computedRegieFacture"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head(details)>
            <button
              class="btn btn-anomalie"
              :class="{
                desactive: ShowALL == false,
                active: ShowALL == true
              }"
              @click="ShowALL ? hideAllDetails() : showAllDetails()"
            >
              <font-awesome-icon icon="info" />
            </button>
          </template>

          <template v-slot:cell(details)="data">
            <button
              class="btn"
              :class="{
                desactive: data.detailsShowing == false,
                active: data.detailsShowing == true
              }"
              @click="data.toggleDetails"
            >
              <font-awesome-icon icon="info" />
            </button>
          </template>
          <template v-slot:cell(regie_name)="data">
            {{ data.item.regie_name }}
          </template>
          <template v-slot:cell(coeff_101_deroule)="data">
            {{ data.item.coeff_101_deroule }} €
          </template>
          <template v-slot:cell(coeff_101_souffle)="data">
            {{ data.item.coeff_101_souffle }} €
          </template>
          <template v-slot:cell(coeff_101_rampant)="data">
            {{ data.item.coeff_101_rampant }} €
          </template>
          <template v-slot:cell(coeff_102_murs)="data">
            {{ data.item.coeff_102_murs }} €
          </template>
          <template v-slot:cell(coeff_102_murs_iti)="data">
            {{ data.item.coeff_102_murs_iti }} €
          </template>
          <template v-slot:cell(coeff_102_murs_ite)="data">
            {{ data.item.coeff_102_murs_ite }} €
          </template>
          <template v-slot:cell(coeff_102_pignons)="data">
            {{ data.item.coeff_102_pignons }} €
          </template>
          <template v-slot:cell(coeff_103_vide_sanitaire)="data">
            {{ data.item.coeff_103_vide_sanitaire }} €
          </template>
          <template v-slot:cell(coeff_103_plafonds)="data">
            {{ data.item.coeff_103_plafonds }} €
          </template>
          <template v-slot:cell(total_surface_101)="data">
            {{ data.item.total_surface_101 }} m²
          </template>
          <template v-slot:cell(total_surface_102)="data">
            {{ data.item.total_surface_102 }} m²
          </template>
          <template v-slot:cell(total_surface_103)="data">
            {{ data.item.total_surface_103 }} m²
          </template>
          <template v-slot:cell(montant_101)="data">
            {{ data.item.montant_101 }} €
          </template>
          <template v-slot:cell(montant_102)="data">
            {{ data.item.montant_102 }} €
          </template>
          <template v-slot:cell(montant_103)="data">
            {{ data.item.montant_103 }} €
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }} €
          </template>
          <template v-slot:cell(TH_total)="data">
            {{ data.item.TH_total }} €
          </template>
          <template v-slot:cell(pay_previsite)="data">
            <span v-if="data.item.pay_previsite == true">
              <font-awesome-icon icon="check-circle" class="statu" />
            </span>
            <span v-else>
              <font-awesome-icon :icon="['far', 'circle']" class="statu" />
            </span>
          </template>
          <template v-slot:cell(total_tasks)="data">
            {{ data.item.total_tasks }}
          </template>
          <template v-slot:cell(statut)="data">
            <EditableInput
              :editable="cantValid($options.name)"
              champName="statue"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue"
              :updateFunction="validationFactureRegie"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(semaine)="data">
            {{ data.item.semaine }}
          </template>
          <template v-slot:cell(payed_at)="data">
            <EditableInput
              :editable="cantUpdate($options.name)"
              champName="payed_at"
              :item="data.item"
              :value="data.item.payed_at"
              type="datetime"
              editableType="datetime"
              :updateFunction="payedFactureRegie"
            />
          </template>
          <template v-slot:cell(commentaire)="data">
            <commentInput
              :item="data.item"
              :value="data.item.commentaire"
              :updateFunction="newCommentRegie"
            />
          </template>

          <template v-slot:cell(upload)="data">
            <b-button
              v-if="
                computedCantExport &&
                  data.item.uploaded != null &&
                  (data.item.statue == 'encore de paiement' ||
                    data.item.statue == 'facture valid' ||
                    data.item.statue == 'facture refuse' ||
                    data.item.statue == 'paye' ||
                    data.item.statue == 'encore de verification')
              "
              size="sm"
              class="iconDownload mr-1"
              title="download facture"
              @click.prevent.stop="download(data.item)"
            >
              <font-awesome-icon icon="download" />
            </b-button>
            <b-button
              v-if="
                computedCantExport &&
                  data.item.uploaded != null &&
                  (data.item.statue == 'encore de paiement' ||
                    data.item.statue == 'facture valid' ||
                    data.item.statue == 'facture refuse' ||
                    data.item.statue == 'paye' ||
                    data.item.statue == 'encore de verification')
              "
              size="sm"
              class="iconDownload"
              title="open facture"
              @click.prevent.stop="visualisation(data.item)"
            >
              <font-awesome-icon icon="eye" />
            </b-button>
          </template>
          <template v-slot:cell(download)="data">
            <b-button
              v-if="computedCantExport"
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                downloadAppelFacturation({
                  data: data.item,
                  project_type: null
                })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(download_iso)="data">
            <b-button
              v-if="data.item.tasks.length > 0 && computedCantExport"
              size="sm"
              class="iconDownload"
              title="Appel à facturation iso"
              @click.prevent.stop="
                downloadAppelFacturation({
                  data: data.item,
                  project_type: 'ISO'
                })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(download_th)="data">
            <b-button
              v-if="data.item.th_tasks.length > 0 && computedCantExport"
              size="sm"
              class="iconDownload"
              title="Appel à facturation th"
              @click.prevent.stop="
                downloadAppelFacturation({
                  data: data.item,
                  project_type: 'TH'
                })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template #row-details="data">
            <b-card class="cardDetailsFacture" v-if="data.item.tasks">
              <p v-if="data.item.tasks.length > 0">
                <strong>Liste de projets ISO</strong>
              </p>
              <div
                class="body-task mb-2"
                v-for="task in data.item.tasks"
                :key="task.id"
              >
                <span class="span_num"
                  ><b> Numero dossier : </b>{{ task.numero_dossier }}</span
                >
                <span><b> Nom : </b>{{ task.nom }}</span>
                <span><b> Prenom : </b>{{ task.prenom }}</span>
                <span><b> Surface 101 : </b>{{ task.surface_101 }} m²</span>
                <span><b> Surface 102 : </b>{{ task.surface_102 }} m²</span>
                <span><b> Surface 103 : </b>{{ task.surface_103 }} m²</span>
                <span><b> Total surface : </b>{{ task.total_surface }} m²</span>
                <span><b> precarite : </b>{{ task.precarite }}</span>
                <span
                  ><b> Zone climatique: </b>{{ task.zone_climatique }}
                </span>
                <span
                  ><b> Type de chauffage : </b>{{ task.type_chauffage }}
                </span>
              </div>
              <p v-if="data.item.th_tasks.length > 0">
                <strong>Liste de projets TH</strong>
              </p>
              <div
                class="body-task mb-2"
                v-for="task_th in data.item.th_tasks"
                :key="task_th.id"
              >
                <span class="span_num"
                  ><b> Numero dossier : </b>{{ task_th.numero_dossier }}</span
                >
                <span><b> Nom : </b>{{ task_th.nom }}</span>
                <span><b> Prenom : </b>{{ task_th.prenom }}</span>
                <span
                  ><b> Montant total TH: </b
                  >{{ task_th.montant_total_ht }} m²</span
                >
              </div>
            </b-card>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowFactureRegie"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Gestion-des-appels-à-facturation-régie',
  data() {
    return {
      filterRegie: { name: 'tous', id: null },
      filterStatu: null,
      ShowALL: false,
      filteryear: null,
      filterSemaine: null,
      filterPrecarite: null,
      filterChauffage: null,
      filterZone: null,
      ListYear: [],
      ListWeek: [],
      page: 1,
      perPage: 10,
      fields: [
        {
          key: 'details',
          label: '',
          thClass: 'th-color',
          tdClass: 'color'
        },
        {
          key: 'regie_name',
          label: 'Nom',
          thClass: 'th-name-regie',
          tdClass: 'td-name-regie'
        },

        { key: 'semaine', label: 'Semaine' },
        { key: 'coeff_101_deroule', label: '101 CP Déroulé' },
        { key: 'coeff_101_souffle', label: '101 CP SOUFLEE' },
        { key: 'coeff_101_rampant', label: '101 Rampant' },
        { key: 'coeff_102_murs', label: '102 Murs' },
        { key: 'coeff_102_murs_iti', label: 'ITI' },
        { key: 'coeff_102_murs_ite', label: 'ITE' },
        { key: 'coeff_102_pignons', label: '102 PIGNONS' },
        { key: 'coeff_103_vide_sanitaire', label: '103 vide sanitaire' },
        { key: 'coeff_103_plafonds', label: '103 Plafonds' },
        { key: 'total_surface_101', label: 'Total surface 101' },
        { key: 'total_surface_102', label: 'Total surface 102' },
        { key: 'total_surface_103', label: 'Total surface 103' },
        { key: 'montant_101', label: 'Montant 101' },
        { key: 'montant_102', label: 'Montant 102' },
        { key: 'montant_103', label: 'Montant 103' },
        {
          key: 'pay_previsite',
          label: 'Payer previsite',
          thClass: 'thPayPrevisite'
        },
        { key: 'total', label: 'Total' },
        { key: 'TH_total', label: 'total TH' },
        { key: 'total_tasks', label: 'Nombre de dossier' },

        { key: 'commentaire', label: 'Commentaire' },
        { key: 'upload', label: 'Facture' },
        {
          key: 'download_iso',
          label: 'Appel à facturation ISO'
        },
        {
          key: 'download_th',
          label: 'Appel à facturation TH'
        },
        {
          key: 'download',
          label: 'Appel à  facturation'
        },
        {
          key: 'payed_at',
          label: 'Date de paiement',
          thClass: 'thvalidationR',
          tdClass: 'tdvalidationR'
        },
        {
          key: 'statut',
          label: 'Statut',
          thClass: 'thpaiementR',
          tdClass: 'tdpaiementR'
        }
      ],
      newFields: [
        {
          key: 'details',
          label: '',
          thClass: 'th-color',
          tdClass: 'color'
        },
        {
          key: 'regie_name',
          label: 'Nom',
          thClass: 'th-name-regie',
          tdClass: 'td-name-regie'
        },

        { key: 'semaine', label: 'Semaine' },
        { key: 'total_surface_101', label: 'Total surface 101' },
        { key: 'total_surface_102', label: 'Total surface 102' },
        { key: 'total_surface_103', label: 'Total surface 103' },
        { key: 'montant_101', label: 'Montant 101' },
        { key: 'montant_102', label: 'Montant 102' },
        { key: 'montant_103', label: 'Montant 103' },
        {
          key: 'pay_previsite',
          label: 'Payer previsite',
          thClass: 'thPayPrevisite'
        },
        { key: 'total', label: 'Total' },
        { key: 'TH_total', label: 'total TH' },
        { key: 'total_tasks', label: 'Nombre de dossier' },

        { key: 'commentaire', label: 'Commentaire' },
        { key: 'upload', label: 'Facture' },
        {
          key: 'download_iso',
          label: 'Appel à facturation ISO'
        },
        {
          key: 'download_th',
          label: 'Appel à facturation TH'
        },
        {
          key: 'download',
          label: 'Appel facture'
        },
        {
          key: 'payed_at',
          label: 'Date de paiement',
          thClass: 'thvalidationR',
          tdClass: 'tdvalidationR'
        },
        {
          key: 'statut',
          label: 'Statut',
          thClass: 'thpaiementR',
          tdClass: 'tdpaiementR'
        }
      ],
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En cours de validation' },
        { value: 'refuse', text: 'Réfusé' },
        { value: 'valid', text: 'appel à facturation validé' },
        {
          value: 'encore de verification',
          text: 'En cours de vérification'
        },
        { value: 'facture valid', text: ' facture validé' },
        { value: 'facture refuse', text: ' facture réfusé' },

        { value: 'encore de paiement', text: 'En cours de paiement' },
        { value: 'paye', text: 'Payé' }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      ListPrecarite: [
        { value: null, text: 'Tous' },
        { value: 'classique', text: 'Classique' },
        { value: 'grande', text: 'Grande' },
        { value: 'simple', text: 'Simple' }
      ],
      ListChauffage: [
        { value: null, text: 'Tous' },
        { value: 'combustible', text: 'Combustible' },
        { value: 'electrique', text: 'Electrique' }
      ],
      ListZone: [
        { value: null, text: 'Tous' },
        { value: 'h1', text: 'H1' },
        { value: 'h2', text: 'H2' },
        { value: 'h3', text: 'H3' }
      ],
      details: false,
      project_type: [
        { value: 'ISO', text: 'ISO' },
        { value: 'TH', text: 'TH' }
      ],
      filterProjectType: 'ISO'
    };
  },
  methods: {
    ...mapActions([
      'getFactureFiltredForRegie',
      'newCommentRegie',
      'validationFactureRegie',
      'fetchAllRegieForAdmin',
      'DownloadingRegieBill',
      'payedFactureRegie',
      'downloadFactureRegiePDF'
    ]),
    showAllDetails() {
      this.computedRegieFacture.forEach(item => {
        this.$set(item, '_showDetails', true);
      });
      this.ShowALL = !this.ShowALL;
    },
    hideAllDetails() {
      this.computedRegieFacture.forEach(item => {
        this.$set(item, '_showDetails', false);
      });
      this.ShowALL = !this.ShowALL;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.statue == 'refuse') return 'ligneRefuse';
      else if (item.statue == 'valid') return 'ligneAccepted';
      else if (item.statue == 'encore de verification')
        return 'ligneVerification';
      else if (item.statue == 'facture valid') return 'ligneValidF';
      else if (item.statue == 'facture refuse') return 'ligneRefuseF';
      else if (item.statue == 'encore de paiement') return 'ligneEnAttend';
      else if (item.statue == 'paye') return 'lignePaye';
      else return 'ligneEnCourDeValidation';
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      localStorage.setItem(
        'gestion-facture-admin-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterStatu: this.filterStatu,
          filterRegie: this.filterRegie
        })
      );
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        precarite: this.filterPrecarite,
        type_de_chauffage: this.filterChauffage,
        zonz_climatique: this.filterZone,
        filterProjectType: this.filterProjectType
      });
    },
    handleChangeYear() {
      var Filters = JSON.parse(
        localStorage.getItem('gestion-facture-admin-Filters')
      );
      Filters.filteryear = this.filteryear;
      localStorage.setItem(
        'gestion-facture-admin-Filters',
        JSON.stringify(Filters)
      );
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        precarite: this.filterPrecarite,
        type_de_chauffage: this.filterChauffage,
        zonz_climatique: this.filterZone,
        filterProjectType: this.filterProjectType
      });
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        precarite: this.filterPrecarite,
        type_de_chauffage: this.filterChauffage,
        zonz_climatique: this.filterZone,
        filterProjectType: this.filterProjectType
      });
      this.ShowALL = false;
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      this.getFactureFiltredForRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        statu: this.filterStatu,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        precarite: this.filterPrecarite,
        type_de_chauffage: this.filterChauffage,
        zonz_climatique: this.filterZone,
        filterProjectType: this.filterProjectType
      });
      this.ShowALL = false;
    },
    download(item) {
      if (item.uploaded != null) {
        this.DownloadingRegieBill(item);
      }
    },
    visualisation(item) {
      window.open(item.uploaded.path, '_blank');
    },
    handleDetails() {
      this.details = !this.details;
    },
    downloadAppelFacturation(item) {
      this.downloadFactureRegiePDF({
        item: item.data,
        year: this.filteryear,
        project_type: item.project_type
      });
    }
  },

  computed: {
    ...mapGetters([
      'getChargementFactureRegie',
      'getTotalRowFactureRegie',
      'getFactureRegie',
      'getChargementUploadFactureRegie',
      'getAllRegie',
      'cantExport',
      'cantValid',
      'cantUpdate'
    ]),
    computedCantExport() {
      return this.cantExport(this.$options.name);
    },
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    computedRegieFacture() {
      if (this.filterStatu != null) {
        return this.getFactureRegie.filter(
          row => row.statue == this.filterStatu
        );
      }
      return this.getFactureRegie;
    },
    computedgetAllRegie() {
      return [{ id: null, name: 'tous' }, ...this.getAllRegie];
    },
    computedValidationList() {
      return [
        {
          value: 'en attend',
          full_name: 'En cours de validation',
          $isDisabled: true
        },
        { value: 'refuse', full_name: 'Réfusé' },
        { value: 'valid', full_name: 'Appel à facturation validé' },
        {
          value: 'encore de verification',
          full_name: 'En cours de vérification'
        },
        { value: 'facture valid', full_name: 'Facture validé' },
        { value: 'facture refuse', full_name: 'Facture réfusé' },
        { value: 'encore de paiement', full_name: 'En cours de paiement' },

        { value: 'paye', full_name: 'Payé' }
      ];
    },
    computedFields() {
      if (this.details == true) {
        if (this.filterProjectType == 'ISO') {
          return this.fields.filter(
            item => item.key != 'TH_total' && item.key != 'download_th'
          );
        } else if (this.filterProjectType == 'TH') {
          return this.fields.filter(
            item =>
              item.key == 'details' ||
              item.key == 'regie_name' ||
              item.key == 'semaine' ||
              item.key == 'total_tasks' ||
              item.key == 'commentaire' ||
              item.key == 'download' ||
              item.key == 'payed_at' ||
              item.key == 'statut' ||
              item.key == 'TH_total' ||
              item.key == 'download_th'
          );
        } else {
          return this.fields;
        }
      } else {
        if (this.filterProjectType == 'ISO') {
          return this.newFields.filter(
            item => item.key != 'TH_total' && item.key != 'download_th'
          );
        } else if (this.filterProjectType == 'TH') {
          return this.newFields.filter(
            item =>
              item.key == 'details' ||
              item.key == 'regie_name' ||
              item.key == 'semaine' ||
              item.key == 'total_tasks' ||
              item.key == 'commentaire' ||
              item.key == 'download' ||
              item.key == 'payed_at' ||
              item.key == 'statut' ||
              item.key == 'TH_total' ||
              item.key == 'download_th'
          );
        } else {
          return this.newFields;
        }
      }
    }
  },

  components: {
    commentInput: () => import('../component/CommentInput.vue'),
    SelectComponent: () => import('../SelectComponent.vue'),
    palatteColor: () => import('../component/paletteColor.vue'),
    EditableInput: () => import('../component/EditableInput.vue')
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('gestion-facture-admin-Filters')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('gestion-facture-admin-Filters')
      ).filteryear;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('gestion-facture-admin-Filters')
      ).filterSemaine;
      this.filterStatu = JSON.parse(
        localStorage.getItem('gestion-facture-admin-Filters')
      ).filterStatu;
      this.filterRegie = JSON.parse(
        localStorage.getItem('gestion-facture-admin-Filters')
      ).filterRegie;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'gestion-facture-admin-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterStatu: this.filterStatu,
          filterRegie: this.filterRegie
        })
      );
    }
    if (sessionStorage.getItem('page-gestion-facture-admin')) {
      this.page = sessionStorage.getItem('page-gestion-facture-admin');
    } else {
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.fetchAllRegieForAdmin();
    this.getFactureFiltredForRegie({
      semaine: this.filterSemaine,
      year: this.filteryear,
      statu: this.filterStatu,
      filterRegie: this.filterRegie,
      page: this.page,
      per_page: this.perPage,
      precarite: this.filterPrecarite,
      type_de_chauffage: this.filterChauffage,
      zonz_climatique: this.filterZone,
      filterProjectType: this.filterProjectType
    });
  }
};
</script>
<style lang="scss" scoped>
.contentValidation {
  padding: 10px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .customTableGestionRegieAdmin {
    max-height: calc(100vh - 210px) !important;
    margin-bottom: 0px;
  }
  .chargement {
    font-size: 9px;
    .spinner-border {
      height: 21px;
      width: 21px;
    }
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
  }
  .statu {
    font-weight: 600;
    color: #479a57;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #858585;
    color: #858585;
    border-radius: 2px;
    font-size: 11px;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checked {
    color: #2dabe2;
  }
  .display {
    display: none;
  }
  .cardDetailsFacture {
    position: relative;
    background-color: #ededed !important;
    color: #858283;
    .card-body {
      width: 1758px;
    }
    p {
      color: #2dabe2;
      margin-bottom: 0px;
      font-size: 14px;
      width: 134px;
    }
    .body-task {
      display: flex;
      margin-left: 26px;
      span {
        text-align: start;
        min-width: 172px;
      }
      .span_num {
        width: 20%;
      }
    }
  }
}
</style>
